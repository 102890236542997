<template>
  <div class="aborigines-container main-cnt">
    <div class="title">门票列表</div>
    <div class="content">
      <common-table
        ref="ticketTable"
        tableHeight="calc(100vh - 325px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="ScenicApi.sellList"
        :filters="filters"
        :columns="tableColumns"
        @projectChange="projectChange"
        @saleTickets="saleTickets"
      >
      </common-table>
    </div>

    <!-- 售票入口弹框 -->
    <w-dialog
      ref="ticketDialog"
      class="ticket-dialog"
      title="售票信息"
      width="60%"
      btnWidth="140px"
      top="15vh"
      confirmText="确认售票"
      @wConfirm="confirm"
    >
      <div class="ticket-content">
        <div class="left-content">
          <div class="basic-info">
            <div class="ticket-title">{{ ticketInfo?.st_name }}</div>
            <div class="basic-item">
              <span>包含项目</span> {{ ticketInfo?.s_name }}
            </div>
            <div class="basic-item">
              <span>售票单价</span> ￥{{ ticketInfo?.st_price }}
            </div>
          </div>
          <el-form
            ref="formRef"
            :model="formData"
            :rules="formRules"
            label-position="top"
            class="ticket-form"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item prop="num" label="售票数量">
                  <el-input
                    v-model="formData.num"
                    autocomplete="off"
                    placeholder="请输入售票数量"
                    clearable
                    type="number"
                    min="1"
                    @blur="onInputBlur"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="timeArr" label="使用期限">
                  <el-date-picker
                    v-model="formData.timeArr"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    value-format="YYYY-MM-DD"
                    :disabled-date="disabledDate"
                    :disabled="ticketInfo.st_sttid == 2 ? true : false"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <div class="total-money">
              合计金额<span>￥{{ totalMoney() }}</span>
            </div>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="24">
                <el-form-item label="支付方式" prop="payway">
                  <el-radio-group v-model="formData.payway">
                    <template
                      v-for="(el, index) in orderTypeOptions"
                      :key="index"
                    >
                      <el-radio :label="el.orp_id">{{ el.orp_name }}</el-radio>
                    </template>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div
          class="right-content"
          v-loading="faceLoading"
          element-loading-text="上传图片中"
        >
          <div class="right-title">
            <div class="enter-title">游客信息录入</div>
            <div
              class="enter-switch"
              v-if="[1, 3].includes(ticketInfo.st_sttid)"
            >
              <span>使用纸质票</span>
              <el-switch
                v-model="ticketCode"
                inline-prompt
                active-text="是"
                inactive-text="否"
              />
            </div>
          </div>
          <el-empty
            v-if="!visitorList.length"
            description="请先在左侧选择售票数量！"
          />
          <template v-else>
            <div
              class="visitor-item"
              v-for="(el, index) in visitorList"
              :key="index"
            >
              <span class="close-icon" @click="deleteVisitor(index)">
                <el-icon><Close /></el-icon>
              </span>
              <el-form label-position="right" class="visitor-form">
                <el-form-item prop="m_realname" label="游客姓名">
                  <el-input
                    v-model="el.m_realname"
                    autocomplete="off"
                    :id="'visitorName' + index"
                    placeholder="请输入游客姓名"
                    @focus="handleFocus('visitorName', index, index + 1)"
                  />
                </el-form-item>
                <el-form-item prop="m_mobile" label="手机号码">
                  <el-input
                    v-model="el.m_mobile"
                    autocomplete="off"
                    :id="'visitorPhone' + index"
                    placeholder="请输入手机号码"
                    @focus="handleFocus('visitorPhone', index, index + 1)"
                  />
                </el-form-item>
                <el-form-item prop="m_idcard" label="身份证号">
                  <el-input
                    v-model="el.m_idcard"
                    autocomplete="off"
                    :id="'visitorCard' + index"
                    placeholder="请输入身份证号"
                    @focus="handleFocus('visitorCard', index, index + 1)"
                  />
                </el-form-item>
                <el-form-item
                  prop="paper_sn"
                  label="录入票码"
                  v-if="[1, 3].includes(ticketInfo.st_sttid)"
                >
                  <el-input
                    v-model="el.paper_sn"
                    autocomplete="off"
                    :id="'visitorSn' + index"
                    placeholder="请用扫码枪识别票面二维码录入"
                    @keyup.enter="handleSn('visitorName', index, index + 1)"
                  />
                </el-form-item>
              </el-form>
              <div class="upload-box">
                <div
                  class="img-container"
                  v-if="[2, 4].includes(ticketInfo.st_sttid)"
                >
                  <div
                    v-if="el.img && el.img[0] && el.img[0].file_url"
                    class="face-img"
                  >
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="el.img[0].file_url"
                      :preview-src-list="[el.img[0].file_url]"
                      fit="cover"
                    >
                    </el-image>
                    <span class="del" @click="deleteFile('', index)">
                      <el-icon><Close /></el-icon>
                    </span>
                  </div>
                  <el-dropdown v-else>
                    <div class="into-face">录入人脸</div>
                    <template #dropdown>
                      <el-dropdown-menu class="face-dropdown-menu">
                        <el-dropdown-item class="face-dropdown-item">
                          <img-upload
                            uploadTitle="人脸照片"
                            :limit="1"
                            uploadText="本地上传"
                            @uploadFile="uploadFile($event, index)"
                            @deleteFile="deleteFile($event, index)"
                            @uploadLoading="uploadLoading"
                          ></img-upload
                        ></el-dropdown-item>
                        <el-dropdown-item
                          @click="openFaceDialog(index)"
                          class="face-dropdown-item"
                          >拍照录入</el-dropdown-item
                        >
                      </el-dropdown-menu></template
                    >
                  </el-dropdown>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </w-dialog>
    <StatusDialog
      ref="loadingDialog"
      :type="1"
      :title="lastPayway ? '等待支付中' : '确认订单中'"
      :btnWidth="'200px'"
      @lConfirm="CloseLoadingDialog"
    >
      <div class="status-tips">
        <div v-if="lastPayway">
          请提醒游客使用 <span>{{ lastPayway }}</span> 支付订单
        </div>
        <el-input
          v-if="lastPayway && lastPayway != '现金'"
          class="barcode-input"
          v-model="barCodeInput"
          ref="barCodeInputRef"
          @keyup.enter="barCodeInfo"
          placeholder="请将光标聚焦在输入框内扫码收款"
        ></el-input>
      </div>
    </StatusDialog>
    <StatusDialog
      ref="succesDialog"
      :type="2"
      title="收费成功"
      @lConfirm="CloseStatusDialog"
      @rConfirm="CloseStatusDialog"
    >
    </StatusDialog>
    <StatusDialog
      ref="errorDialog"
      :type="3"
      :title="errorTitle"
      cancelText="编辑订单"
      confirmText="重新发起"
      @lConfirm="CloseErrorDialog"
      @rConfirm="confirm"
    >
    </StatusDialog>
    <FaceDialog ref="faceDialogRef" @submit="handleCamera"></FaceDialog>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick } from "vue";
import { ElMessage } from "element-plus";
import { BasicApi, ScenicApi } from "@/plugins/api.js";
import { Close } from "@element-plus/icons-vue";
import imgUpload from "@/components/img-upload/img-upload.vue";
import StatusDialog from "@/components/status-dialog/status-dialog.vue";
import FaceDialog from "../components/FaceDialog.vue";
import { useStore } from "vuex";
import { checkStr } from "@/utils/common.js";
import dayjs from "dayjs";
const store = useStore();
/** 筛选条件列表 */
const filters = ref([
  {
    filterType: "select",
    name: "p_id",
    value: "",
    placeholder: "请选择项目",
    options: [],
    val: "p_id",
    lab: "p_name",
    action: "projectChange",
  },
  {
    filterType: "select",
    name: "s_id",
    value: "",
    placeholder: "请选择景区",
    options: [],
    val: "s_id",
    lab: "s_name",
  },
]);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "st_id",
    label: "门票ID",
    minWidth: 50,
    color: "--text-third-color",
  },
  {
    prop: "st_name",
    label: "门票名称",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "belong_name",
    label: "包含项目",
    minWidth: 200,
    color: "--text-third-color",
    showTooltip: true,
  },
  {
    prop: "stt_name",
    label: "门票类型",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "use_info",
    label: "使用期限",
    minWidth: 160,
    color: "--text-third-color",
  },
  {
    prop: "st_price",
    label: "门票售价",
    minWidth: 100,
    color: "--theme-color",
  },
  {
    prop: "all_stock",
    label: "库存",
    minWidth: 100,
    color: "--text-third-color",
  },
  {
    prop: "buyed_stock",
    label: "已售",
    minWidth: 100,
    color: "--warning-color",
  },
  {
    prop: "st_stock",
    label: "余票",
    minWidth: 100,
    color: "--text-color",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 200,
    bottons: [
      {
        name: "售票",
        action: "saleTickets",
        token: "n_Y07Z8iMwoVoiQWYmLLnHnVNgMf0Y",
        className: "theme-font-btn",
      },
    ],
  },
]);
/** 餐厅表格数据 */
const ticketTable = ref(null);
/** 项目选择更改 */
const projectChange = (obj) => {
  filters.value[1].value = "";
  filters.value[1].options = [];
  ticketTable.value.tableLoad();

  if (obj.value) {
    // 获取景区列表数据
    BasicApi.getScenicByProject({ pid: obj.value }).then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取景区数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};
const ticketCode = ref(false);
/** 新增/编辑弹框 */
const ticketDialog = ref(null);
// 识别身份
const connectAddress = ref("127.0.0.1:30004");
// function cleanMsg(index) {
//   if (visitorList.value && visitorList.value.length) {
//     visitorList.value[index].m_realname = "";
//     visitorList.value[index].m_idcard = "";
//   }
// }
function hex2a(hex) {
  let str_list = "";
  for (let i = 0; i < hex.length && hex.substr(i, 2) !== "00"; i += 2) {
    const a = hex.charCodeAt(i);
    const b = hex.charCodeAt(i + 1);
    const c = b * 256 + a;
    str_list += String.fromCharCode(c);
  }
  return str_list.toString();
}
const ws = ref(null);
function getIdentify() {
  const webUrl = "ws://" + connectAddress.value + "/ws";
  ws.value = new WebSocket(webUrl);

  // ws.value.onopen = function () {
  // let szhelp =
  //   "websocket连接成功，url[" +
  //   webUrl +
  //   "]，读卡器上放置身份证后websocket会自动接收身份证数据，如需手动操作请调用WS_ReadInfo()函数\r\n\r\n";
  // szhelp += "支持被动接收和主动请求两种方式\r\n";
  // szhelp +=
  //   "被动接收:当读卡器刷卡成功后会推送身份证信息到websocket，websocket直接显示即可\r\n";
  // szhelp +=
  //   "主动请求:支持网页端主动向服务器请求对应的消息。可查看<WS_ReadInfo><WS_GetASN><WS_GetBCardNo>这三个接口";
  // console.log(szhelp);
  // };
  ws.value.onclose = function () {
    console.log("websocket已断开");
  };
  ws.value.onmessage = function (messageEvent) {
    const jsonobject = JSON.parse(messageEvent.data);
    if (jsonobject.Ret == 0) {
      if (jsonobject.Cmd == 10001) {
        // cleanMsg(index);
        console.log("websocket 协议 读取身份证成功");
        const szparam = JSON.parse(window.atob(jsonobject.UserParam));

        const name = hex2a(window.atob(szparam.CardInfo.Name));
        const m_realname = name.replace(/ /g, "");
        const m_idcard = hex2a(window.atob(szparam.CardInfo.No));

        if (
          document.getElementById(nameRef.value + nameIndex.value) ==
          document.activeElement
        ) {
          visitorList.value[nameIndex.value].m_realname = m_realname;
          visitorList.value[nameIndex.value].m_idcard = m_idcard;

          if (ticketCode.value) {
            if (!visitorList.value[nameIndex.value]["paper_sn"]) {
              const visitorSnRef = document.getElementById(
                "visitorSn" + nameIndex.value
              );
              visitorSnRef.focus();
            } else {
              const visitorNameRef = document.getElementById(
                nameRef.value + nextIndex.value
              );
              if (visitorNameRef) {
                visitorNameRef.focus();
              }
            }
          }

          if (!ticketCode.value) {
            // 周期票不用跳到下一个 要上传人脸
            if ([1, 3].includes(ticketInfo.value.st_sttid)) {
              const visitorNameRef = document.getElementById(
                nameRef.value + nextIndex.value
              );
              if (visitorNameRef) {
                visitorNameRef.focus();
              }
            }
          }
        }
      }
    } else if (jsonobject.Cmd == 30401) {
      const szparam = JSON.parse(window.atob(jsonobject.UserParam));
      console.log("websocket 协议 读取A卡SN成功：" + szparam.SN);
    } else if (jsonobject.Cmd == 20401) {
      const szparam = JSON.parse(window.atob(jsonobject.UserParam));
      console.log("websocket 协议 读取身份证卡片SN成功：" + szparam.SN);
    } else if (jsonobject.Cmd == 20511) {
      const szparam = JSON.parse(window.atob(jsonobject.UserParam));
      console.log("websocket 协议 读卡器唯一号：" + szparam.SN);
    } else if (jsonobject.Cmd == 1000) {
      const szparam = JSON.parse(window.atob(jsonobject.UserParam));
      if (szparam.State == 0) {
        console.log("读卡器已被拔出");
      } else {
        console.log("读卡器已插入");
      }
    } else {
      console.log("websocket 协议调用失败，原因：" + jsonobject.ErrInfo);
    }
  };
}
const nameIndex = ref(null);
const nextIndex = ref(null);
const nameRef = ref("");
function handleSn(data, index, index2) {
  const visitorSnRef = document.getElementById(data + index); // 当前名字框
  const nextVisitorSnRef = document.getElementById(data + index2); // 下一个名字框
  if (!visitorList.value[index]["m_realname"]) {
    visitorSnRef && visitorSnRef.focus();
  } else {
    nextVisitorSnRef && nextVisitorSnRef.focus();
  }
}
function handleFocus(data, index, index2) {
  // 聚焦状态
  nameRef.value = data;
  nameIndex.value = index;
  nextIndex.value = index2;
}
// 表单
const formRef = ref(null);
const commonFormRules = {
  num: [
    {
      required: true,
      message: "请输入售票数量",
      trigger: "blur",
    },
  ],
  payway: [
    {
      required: true,
      message: "请选择支付方式",
      trigger: "change",
    },
  ],
};
const formRules = ref();
const formData = ref({
  num: "",
  payway: null,
  timeArr: [],
});
const loadingDialog = ref(null);
const succesDialog = ref(null);
const errorDialog = ref(null);
const barCodeInputRef = ref(null);
const barCodeInput = ref("");
const errorTitle = ref("订单支付失败");
const lastOrderSn = ref("");

function barCodeInfo() {
  const lastParmas = {
    order_sn: lastOrderSn.value,
    auth_code: barCodeInput.value,
  };
  barCodeInput.value &&
    ScenicApi.barCodeInfo(lastParmas).then((res) => {
      if (res.Code == 200) {
        if (res.Data.Code == 200) {
          barCodeInput.value = "";
          succesDialog.value.show();
          loadingDialog.value.close();
          lastOrderSn.value = "";
        } else if (res.Data.Code == 205) {
          setTimeout(() => {
            barCodeInfo();
          }, 3000);
        } else {
          barCodeInput.value = "";
          errorTitle.value = res.Data.Message || "订单支付失败";
          loadingDialog.value.close();
          errorDialog.value.show();
          lastOrderSn.value = "";
        }
      } else {
        loadingDialog.value.close();
        errorDialog.value.show();
        lastOrderSn.value = "";
      }
    });
}
const lastPayway = ref(null);
function getOrderInfo(orderSnParmas) {
  ScenicApi.orderInfo(orderSnParmas).then((res) => {
    if (res.Code == 200) {
      lastPayway.value = res.Data.payway_name;
      if (res.Data.or_pay_status == 2) {
        //支付成功
        succesDialog.value.show();
        loadingDialog.value.close();
      } else if (res.Data.or_pay_status == 1) {
        nextTick(() => {
          barCodeInputRef.value.focus();
        });
        lastOrderSn.value = res.Data.or_sn;
        // timer.value = window.setInterval(() => {
        //   setTimeout(() => {
        //     barCodeInfo({ order_sn: res.Data.or_sn });
        //   }, 0);
        // }, 3000);
      }
    }
  });
}
function CloseErrorDialog() {
  barCodeInput.value = "";
  lastOrderSn.value = "";

  errorDialog.value.close();
}
function CloseLoadingDialog() {
  lastOrderSn.value = "";

  barCodeInput.value = "";
  loadingDialog.value.close();
}

function confirm() {
  if (formRef.value) {
    formRef.value.validate((valid) => {
      if (valid) {
        lastPayway.value = null;
        if (!visitorList.value.length) {
          ElMessage.warning("请录入游客信息！");
          return false;
        } else {
          let buy_user = [];

          // st_sttid 1-常规票 2-周期性票 3-活动常规票 4-性票
          // 1【不需要录入人脸】【票码非必填】
          // 【使用期限 a、当天在返回的时间范围之后 那么默认显示当天  b、当天在返回范围之内 那么默认显示当天  c、当天在返回之前 默认显示返回的第一天 选择范围都是后端返回的开始结束时间范围】
          // 2【需要录入人脸】【不需要票码】【使用期限 选择范围是当天 --> st_use_days - 1】
          // 3【不需要录入人脸】【票码非必填】【使用期限同1】
          // 4【需要录入人脸】【不需要票码】【使用期限同1】

          // 线下售票
          for (var i = 0; i < visitorList.value.length; i++) {
            if (!visitorList.value[i].m_realname) {
              ElMessage.warning("请将游客的姓名补充完整！");
              return false;
            } else if (!checkStr(visitorList.value[i].m_realname, "chinese")) {
              ElMessage.warning(`请输入正确的游客姓名！`);
              visitorList.value[i].m_realname = "";

              return false;
            }
            if (visitorList.value[i].m_mobile) {
              if (!checkStr(visitorList.value[i].m_mobile, "phone")) {
                ElMessage.warning(
                  `游客${visitorList.value[i].m_realname}的手机号格式错误！`
                );
                visitorList.value[i].m_mobile = "";
                return false;
              }
            }

            if (!visitorList.value[i].m_idcard) {
              ElMessage.warning(
                `请将游客${visitorList.value[i].m_realname}的身份证补充完整！`
              );
              return false;
            } else if (!checkStr(visitorList.value[i].m_idcard, "card")) {
              ElMessage.warning(
                `游客${visitorList.value[i].m_realname}的身份证格式错误！`
              );
              visitorList.value[i].m_idcard = "";
              return false;
            }

            if (
              [2, 4].includes(ticketInfo.value.st_sttid) &&
              !visitorList.value[i].file_key
            ) {
              ElMessage.warning(
                `游客${visitorList.value[i].m_realname}的人脸未录入！`
              );
              return false;
            }
            if ([1, 3].includes(ticketInfo.value.st_sttid)) {
              buy_user.push({
                m_idcard: visitorList.value[i].m_idcard,
                m_mobile: visitorList.value[i].m_mobile,
                m_realname: visitorList.value[i].m_realname,
                paper_sn: visitorList.value[i].paper_sn,
              });
            } else {
              buy_user.push({
                m_idcard: visitorList.value[i].m_idcard,
                m_mobile: visitorList.value[i].m_mobile,
                m_realname: visitorList.value[i].m_realname,
                file_key: visitorList.value[i].file_key,
              });
            }
          }

          const parmas = {
            stid: ticketInfo.value.st_id,
            sid: ticketInfo.value.st_sid,
            payway: formData.value.payway,
            num: formData.value.num,
            buy_user: buy_user,
          };

          ScenicApi.sellTicket(parmas).then((res) => {
            if (res.Code === 200) {
              const orderSnParmas = {
                order_sn: res.Data.or_sn,
              };
              loadingDialog.value.show();
              setTimeout(() => {
                getOrderInfo(orderSnParmas);
              }, 2000);
            } else {
              let msg = res.Message ? res.Message : `售票失败！`;
              ElMessage.error(msg);
            }
          });
        }
      }
    });
  }
}
function CloseStatusDialog() {
  lastOrderSn.value = "";

  barCodeInput.value = "";
  errorDialog.value.close();
  succesDialog.value.close();
  ticketDialog.value.close();
  ticketTable.value.tableLoad();
}
const visitorList = ref([]);
const visitorObj = ref({
  m_realname: "",
  m_mobile: "",
  m_idcard: "",
  paper_sn: "",
  img: [],
});
function disabledDate(current) {
  if (ticketInfo.value.st_sttid != 2) {
    return (
      dayjs(current).unix() < dayjs(ticketInfo.value.st_use_stime).unix() ||
      dayjs(current).unix() > dayjs(ticketInfo.value.st_use_etime).unix()
    );
  }
}

const ticketInfo = ref({});
function getTicketInfo(row) {
  store.dispatch("getQiniuData");
  visitorList.value = [];

  ScenicApi.ticketInfo({ st_id: row.st_id }).then((res) => {
    if (res.Code === 200) {
      ticketInfo.value = res.Data;
      formData.value = {
        num: "",
        payway: null,
      };

      if (ticketInfo.value.st_sttid == 2) {
        formRules.value = {
          ...commonFormRules,
        };
        formData.value["timeArr"] = [
          dayjs().format("YYYY-MM-DD"),
          dayjs()
            .add(+ticketInfo.value.st_use_days - 1, "day")
            .format("YYYY-MM-DD"),
        ];
      } else {
        const currentDay = dayjs().unix();
        const beginDay = dayjs(ticketInfo.value.st_use_stime).unix();

        if (currentDay < beginDay) {
          formData.value["timeArr"] = [
            dayjs(ticketInfo.value.st_use_stime).format("YYYY-MM-DD"),
            dayjs(ticketInfo.value.st_use_stime).format("YYYY-MM-DD"),
          ];
        } else {
          formData.value["timeArr"] = [
            dayjs().format("YYYY-MM-DD"),
            dayjs().format("YYYY-MM-DD"),
          ];
        }
        formRules.value = {
          ...commonFormRules,
          timeArr: [
            {
              required: true,
              message: "请输入使用期限",
              trigger: "blur",
            },
          ],
        };
      }
      visitorList.value = [];
      if (ws.value) {
        ws.value.close();
      }
      nameIndex.value = null;
      nameRef.value = "";
      nextIndex.value = null;
      ticketCode.value = false;
      ticketDialog.value.show();
      getIdentify();
    } else {
      ElMessage.error(res.Message);
    }
  });
}

const saleTickets = (row) => {
  setTimeout(() => {
    getTicketInfo(row);
  }, 200);
};
function roundFractional(x, n) {
  return Math.round(x * Math.pow(10, n)) / Math.pow(10, n);
}
function totalMoney() {
  let result = null;
  const price = ticketInfo.value.st_price || 0;
  const number = formData.value.num || 0;

  result = price * number;

  return roundFractional(result, 2);
}
function onInputBlur() {
  visitorList.value = [];

  if (formData.value.num >= 1) {
    for (var i = 0; i < +formData.value.num; i++) {
      const arr = JSON.parse(JSON.stringify(visitorObj.value));
      visitorList.value.push(arr);
    }
  }
}
function deleteVisitor(index) {
  formData.value.num == 1
    ? (formData.value.num = null)
    : (formData.value.num = +formData.value.num - 1);
  visitorList.value.splice(index, 1);
}

const faceLoading = ref(false);
function uploadLoading(flag) {
  faceLoading.value = flag;
}
/** 图片上传 */
const uploadFile = (obj, index) => {
  faceLoading.value = false;
  visitorList.value[index].img = [{ file_url: obj.standard_url }];
  visitorList.value[index].file_key = obj.key;
};
/** 图片删除 */
const deleteFile = (obj, index) => {
  visitorList.value[index].img = [];
  visitorList.value[index].file_key = "";
};
/** 获取项目数据 */
const getProjectData = () => {
  BasicApi.projectSelect().then((res) => {
    if (res.Code === 200) {
      filters.value[0].options = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取项目数据失败！";
      ElMessage.error(msg);
    }
  });
};
const orderTypeOptions = ref([]);
const getOrderType = () => {
  ScenicApi.getOrderType().then((res) => {
    if (res.Code === 200) {
      orderTypeOptions.value = res.Data;
    } else {
      let msg = res.Message ? res.Message : "获取支付方式失败！";
      ElMessage.error(msg);
    }
  });
};
const faceDialogRef = ref(null);
const currentCameraIndex = ref(null);
function openFaceDialog(index) {
  currentCameraIndex.value = index;
  faceDialogRef.value.openDialog();
}
function handleCamera(data) {
  visitorList.value[currentCameraIndex.value].img = [
    { file_url: data.standard_url },
  ];
  visitorList.value[currentCameraIndex.value].file_key = data.key;
  faceDialogRef.value.closeDialog();
}
onMounted(() => {
  // 获取项目数据
  getProjectData();
  getOrderType();
  ticketTable.value.tableLoad();
});
</script>

<style lang="scss">
.face-dropdown-menu .face-dropdown-item .img-upload > ul .upload-text {
  color: #606266;
  cursor: pointer;
}
.face-dropdown-menu .face-dropdown-item:hover .img-upload > ul .upload-text {
  color: var(--theme-color);
}
.face-dropdown-menu .face-dropdown-item {
  padding: 0;
  width: 100px;
  display: flex;
  line-height: 1;
  justify-content: center;
}
.face-dropdown-item:nth-of-type(1) {
  .upload-text {
    height: 36px;
    line-height: 36px;
    display: inline-block;
  }
}
.face-dropdown-item:last-child {
  line-height: 36px !important;
}
.aborigines-container {
  font-family: "Source Han Sans CN";
  .content {
    padding: 20px;
  }
  .el-form-item__content {
    .el-date-editor,
    .el-select {
      width: 100%;
      .el-range-input {
        background-color: transparent;
      }
    }
  }
  .ticket-dialog {
    .el-dialog__body {
      padding: 0;
      .ticket-content {
        height: 520px;

        display: flex;
        .right-content {
          width: 40%;
          border-left: 1px solid #eee;
          overflow-y: auto;
          padding: 20px;
          .el-empty__image {
            width: 100px;
          }
          .right-title {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 20px;

            .enter-title {
              color: var(--text-third-color);
              font-size: 16px;
            }
            .enter-switch {
              display: flex;
              align-items: center;
              > span {
                color: var(--text-gray-color);
                padding-right: 6px;
              }
            }
          }
          .visitor-item {
            border: 2px dashed #eee;
            padding: 10px 30px 10px 10px;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            position: relative;
            .close-icon {
              position: absolute;
              right: 4px;
              top: 4px;
              > i {
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
                &:hover {
                  color: var(--theme-color);
                }
              }
            }
            .upload-box {
              text-align: right;
              display: flex;
              align-items: center;

              .img-container {
                .el-dropdown {
                  position: absolute;
                  color: var(--theme-color);
                  right: 16px;
                  bottom: 20px;
                  cursor: pointer;
                }
                .face-img {
                  position: relative;
                  .el-image {
                    width: 56px !important;
                    height: 56px !important;
                    border-radius: 6px;
                  }
                  .del {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 13px;
                    width: 18px;
                    line-height: 13px;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0.5);
                    border-radius: 0 4px 0 4px;
                    text-align: center;
                    font-size: 12px;
                    cursor: pointer;
                  }
                }
              }
            }

            .visitor-form {
              flex: 1;

              .el-form-item__content {
                height: 20px;
                line-height: 20px;
              }
              .el-form-item__label {
                padding-right: 0;
                user-select: none;
              }
              .el-input__inner {
                padding-right: 10px;
              }
              .el-form-item {
                width: 80%;
                align-items: center;
                margin-bottom: 8px;
                .el-input__inner {
                  height: 20px;
                  line-height: 20px;
                }
                .el-form-item__error {
                  top: 68%;
                  left: 15px;
                }
              }

              .el-form-item__content .el-input__inner,
              .el-form-item__content .el-textarea__inner {
                background-color: transparent;
              }
            }
          }
        }
        .left-content {
          flex: 1;
          overflow-y: auto;
          padding: 20px;
          .basic-info {
            width: 100%;
            background-color: var(--theme-bg-color);
            padding: 10px;
            border-radius: 4px;
            .ticket-title {
              font-weight: 700;
              font-size: 16px;
            }
            .basic-item {
              margin-top: 10px;
              width: 100%;
              color: var(--text-third-color);
              > span {
                color: var(--text-gray-color);
              }
            }
          }
          .ticket-form {
            margin-top: 20px;
            .total-money {
              color: var(--text-third-color);
              > span {
                margin-left: 4px;
                color: var(--text-color);
                font-weight: bold;
                font-size: 20px;
              }
            }
            .el-form-item__label {
              color: var(--text-third-color);
              padding-bottom: 4px;
            }
          }
        }
      }
    }

    .el-dialog__footer {
      padding: 30px;
      border-top: 1px solid #eee;
    }
  }
  .barcode-input {
    width: 100%;
    margin-top: 30px;
  }
  .status-tips {
    color: var(--text-third-color);
    margin-top: 20px;
    span {
      color: var(--theme-color);
    }
  }
}
</style>
